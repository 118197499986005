import { ThemeProvider } from '@mui/material/styles';
import { Player } from './components/player';
import './index.css';
import theme from './theme/config.js';
import ErrorBoundary from './components/ErrorBoundary';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <Player printLoadTimer />
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default App;
