import { getEnvironmentVar } from '../lib/environment';
import { useErrorHandler } from 'react-error-boundary';

const storefrontApi = getEnvironmentVar('REACT_APP_STOREFRONT_API');

const useStorefrontAPI = () => {
  const handleError = useErrorHandler();

  const getProduct = (productId) => {
    return fetch(`${storefrontApi}/products/${productId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.status);
        }
        return response.json();
      })
      .catch((error) => {
        handleError(error);
      });
  };

  return { getProduct };
};

export default useStorefrontAPI;
