const useMessage = () => {
  const postMessage = (data, target, origin = '*') => target.postMessage(data, origin);

  const sendToParent = (data) => {
    const { parent } = window;
    postMessage(data, parent);
  };
  return { sendToParent };
};

export default useMessage;
