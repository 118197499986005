import { createTheme } from '@mui/material/styles';
import colors from './colors.js';

export default createTheme({
  typography: {
    fontFamily: ["'Inter'", 'sans-serif'].join(','),
  },
  palette: {
    configurator: {
      ...colors,
    },
  },
});
