import Grid from '@mui/material/Grid';

const CenteredGrid = ({ children }) => (
  <Grid
    container
    direction="column"
    alignItems="center"
    justifyContent="center"
    style={{ height: '100%' }}
  >
    {children}
  </Grid>
);

export default CenteredGrid;
