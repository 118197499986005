import Storefront from '@unity/storefront-player-webgl';
import { useCallback, useEffect, useState } from 'react';
import { PLAYER_ACTIONS } from '../constants/playerActions';
import { PLAYER_STATES } from '../constants/playerStates';
import useAnalytics from '../hooks/useAnalytics';
import useMessage from '../hooks/useMessage';
import useQuery from '../hooks/useQuery';
import useTimer from '../hooks/useTimer';
import useStorefrontAPI from '../hooks/useStorefrontAPI';
import { usePlayerStyles } from '../hooks/usePlayerStyles';
import logger from '../lib/logger';
import CenteredGrid from './CenteredGrid';
import { useMatch } from 'react-router-dom';
import { GltfPlayer } from './gltf-player';
import { IndigoPlayer } from './indigo-player';
import { CircularProgress, Box } from '@mui/material';

export const Player = ({ printLoadTimer }) => {
  const queryParams = useQuery();
  const { track, Events } = useAnalytics();
  const { sendToParent } = useMessage();
  const { getProduct } = useStorefrontAPI();
  const { startTimer, endTimer } = useTimer();
  const [sceneManager, setSceneManager] = useState(null);
  const [product, setProduct] = useState(null);
  const [modelLoaded, setModelLoaded] = useState(false);
  const classes = usePlayerStyles();

  const match = useMatch('/products/:productId/*');
  const matchedProductId = match?.params?.productId;

  useEffect(() => {
    const fetchProduct = async () => {
      const { assets, ...rest } = await getProduct(matchedProductId);
      const { url, type } = assets[0];

      setProduct({
        url,
        type,
        productId: matchedProductId,
        ...rest,
      });
    };

    if (!product) {
      if (matchedProductId) {
        fetchProduct();
      } else {
        setProduct(queryParams);
      }
    }
  }, [matchedProductId, queryParams, product, getProduct]);

  const sceneManagerRef = useCallback((ref) => {
    setSceneManager(ref);
  }, []);

  const trackOnLoaded = useCallback(() => {
    const loadTimeMs = endTimer();

    if (printLoadTimer && loadTimeMs) {
      logger.info(`Model loaded in ${loadTimeMs / 1000} seconds.`);
    }

    if (!product.productId || !product.projectId || !loadTimeMs) {
      // no track
      return;
    }

    track(Events.MODEL_VIEW, {
      ...product,
      loadTimeMs,
    });
  }, [product, Events, track, endTimer, printLoadTimer]);

  const onModelLoaded = useCallback(() => {
    sendToParent({ message: PLAYER_STATES.LOADED });
    setModelLoaded(true);
    trackOnLoaded();
  }, [sendToParent, trackOnLoaded, setModelLoaded]);

  const handleMessages = useCallback(
    (event) => {
      if (event.origin !== window.origin) {
        switch (event.data?.message) {
          case PLAYER_ACTIONS.CAPTURE:
            sendToParent({ message: PLAYER_STATES.CAPTURED, data: sceneManager.captureFrame() });
            break;
          default:
            break;
        }
      }
    },
    [sceneManager, sendToParent]
  );

  useEffect(() => {
    window.addEventListener('message', handleMessages);

    return () => {
      window.removeEventListener('message', handleMessages);
    };
  }, [handleMessages]);

  useEffect(() => {
    startTimer();
  }, [startTimer]);

  const setVisibility = (visible) => ({ sx: { visibility: visible ? 'visible' : 'hidden' } });

  return (
    <CenteredGrid className={classes.relative}>
      {product && (
        <>
          {product.type === Storefront.MIME_TYPES.ZIP ? (
            <IndigoPlayer
              {...setVisibility(modelLoaded)}
              url={product.url}
              ref={sceneManagerRef}
              onLoad={onModelLoaded}
            />
          ) : (
            <GltfPlayer
              {...setVisibility(modelLoaded)}
              url={product.url}
              ref={sceneManagerRef}
              onLoad={onModelLoaded}
            />
          )}
        </>
      )}

      <Box
        className={classes.loading}
        sx={{
          display: !modelLoaded ? 'block' : 'none',
        }}
      >
        <CenteredGrid>
          <CircularProgress />
        </CenteredGrid>
      </Box>
    </CenteredGrid>
  );
};

export default Player;
