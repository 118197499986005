import React from 'react';
import Typography from '@mui/material/Typography';
import CenteredGrid from './CenteredGrid';
import errorSvg from '../images/error.svg';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.error) {
      return (
        <CenteredGrid>
          <img src={errorSvg} alt="Error occured" />
          <Typography variant="h5">Oops!</Typography>
          <Typography variant="h6" mt={2} color="grey.500">
            We're having troubles loading your model.
          </Typography>
          <Typography variant="body1" mt={2} color="grey.500">
            ({this.state.error.message})
          </Typography>
        </CenteredGrid>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
