// colours sourced from figma designs
// https://www.figma.com/file/khdMEjT0lF4RpiPb47377M/Unity-Storefront?node-id=1277%3A26309

const colors = {
  gray: {
    900: '#212121',
    800: '#424242',
    300: '#e0e0e0',
  },
  primary: '#0b68ea', // blue
};

export default colors;
