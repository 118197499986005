import { useRef, useCallback } from 'react';

const useTimer = () => {
  const startTime = useRef(null);

  const startTimer = useCallback(() => (startTime.current = Date.now()), [startTime]);

  const endTimer = useCallback(() => {
    if (startTime.current === null) {
      return null;
    }

    const delta = Date.now() - startTime.current;
    startTime.current = null;

    return delta;
  }, []);

  return {
    startTimer,
    endTimer,
  };
};

export default useTimer;
