import { makeStyles } from '@mui/styles';
import theme from '../theme/config.js';

export const usePlayerStyles = makeStyles({
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  configurator: {
    width: '12.5rem',
    height: '100%',
    overflow: 'auto',
    background: theme.palette.configurator.gray[900],
  },
  canvasWrapper: {
    flex: 1,
    position: 'relative',
    overflow: 'hidden',
  },
  loading: {
    position: 'absolute',
    background: 'white',
    width: '100%',
    height: '100%',
  },
  relative: {
    position: 'relative',
  },
});
