import Events from '../constants/analyticsEvents';
import logger from '../lib/logger';
import { getEnvironmentVar } from '../lib/environment';

const unityAnalyticsUrl = getEnvironmentVar('REACT_APP_UNITY_ANALYTICS_URL');
const environment = getEnvironmentVar('NODE_ENV');

const getUnityUUID = (unityAnalyticsUrl) => {
  // see UUID API endpoint
  // https://docs.unity.com/analytics/AnalyticsRestAPI.htm
  const uuidUrl = unityAnalyticsUrl + '/uuid';
  return fetch(uuidUrl)
    .then((response) => {
      if (!response.ok) {
        throw new Error('getUnityUUID failed');
      }
      return response.json();
    })
    .then((data) => data.userID)
    .catch((error) => {
      logger.error(error);
    });
};

const useAnalytics = () => {
  const track = async (event, data) => {
    const { userId, projectId, ...eventParams } = data;

    // body is formed as per Event payload specification
    // https://docs.unity.com/analytics/EventPayload.htm
    const body = {
      userID: userId || (await getUnityUUID(unityAnalyticsUrl)),
      eventUUID: crypto.randomUUID(),
      eventName: event,
      eventParams: {
        userAgent: navigator.userAgent,
        ...eventParams,
      },
    };

    const url = `${unityAnalyticsUrl}/project/${projectId}/${environment}`;

    const init = {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    };

    // Post Unity Analytics event
    fetch(url, init)
      .then((response) => {
        if (!response.ok) {
          throw new Error('useAnalytics.track failed');
        }
      })
      .catch((error) => {
        logger.error(error);
      });
  };

  return {
    track,
    Events,
  };
};

export default useAnalytics;
