import { Box } from '@mui/material';
import { Entity, SceneManager } from '@unity/storefront-player-ui';
import Storefront from '@unity/storefront-player-webgl';
import { forwardRef } from 'react';
import { defaultLightsConfig } from '../constants/defaultLightsConfig';
import { usePlayerStyles } from '../hooks/usePlayerStyles';

export const GltfPlayer = forwardRef(({ url, onLoad }, forwardedRef) => {
  const classes = usePlayerStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.canvasWrapper}>
        <SceneManager ref={forwardedRef}>
          <Entity using={Storefront.GltfEntity}
            options={{ url, boundingCubeSize: 16 }}
            onLoad={onLoad} />
          {defaultLightsConfig.map((light) =>
            <Entity using={Storefront.DirectionalLightEntity}
              options={light}
              key={light.name} />)}
          <Entity using={Storefront.OrbitCameraControlsEntity}
            options={{ position: { x: 0, y: 0, z: 24 } }} />
        </SceneManager>
      </Box>
    </Box>
  );
});
