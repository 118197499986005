import { Box } from '@mui/material';
import { Configurator, Entity, SceneManager } from '@unity/storefront-player-ui';
import Storefront from '@unity/storefront-player-webgl';
import { forwardRef, useCallback, useRef, useState } from 'react';
import { usePlayerStyles } from '../hooks/usePlayerStyles';

export const IndigoPlayer = forwardRef(({ url, onLoad }, forwardedRef) => {
  const classes = usePlayerStyles();

  const indigoEntityRef = useRef(null);

  const [indigoConfig, setIndigoConfig] = useState(null);
  const [indigoCameraControlsEntity, setIndigoCameraControlsEntity] = useState(null);

  const indigoCameraControlsEntityRef = useCallback((ref) => {
    setIndigoCameraControlsEntity(ref);
  }, []);

  const onIndigoConfigLoaded = useCallback(({ config }) => {
    setIndigoConfig(config);
    onLoad();
  }, [setIndigoConfig, onLoad]);

  return (
    <Box className={classes.container}>
      <Box className={classes.configurator}>
        {indigoCameraControlsEntity &&
          <Configurator.IndigoCameraOptions
            options={{ indigoConfig }}
            entity={indigoCameraControlsEntity} />}
        <Configurator.IndigoStyleVariants
          options={{ indigoConfig }}
          entity={indigoEntityRef.current} />
        <Configurator.IndigoMaterialVariants
          options={{ indigoConfig }}
          entity={indigoEntityRef.current} />
      </Box>
      <Box className={classes.canvasWrapper}>
        <SceneManager ref={forwardedRef}>
          {indigoConfig &&
            <Entity using={Storefront.Indigo.CameraControlsEntity}
              options={{ indigoConfig }}
              ref={indigoCameraControlsEntityRef} />}
          <Entity using={Storefront.Indigo.Entity}
            options={{ url }}
            onLoad={onIndigoConfigLoaded}
            ref={indigoEntityRef} />
        </SceneManager>
      </Box>
    </Box>
  );
});
