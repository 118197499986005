export const defaultLightsConfig = [
  {
    name: 'Under',
    position: {
      x: -0.94419234991073608,
      y: -13.609930038452148,
      z: -0.22673064470291138,
    },
    rotation: {
      x: 273.57632446289063,
      y: 127.99038696289063,
      z: 0.23960675299167633,
    },
    targetPosition: {
      x: -0.049161016941070557,
      y: -0.99805259704589844,
      z: 0.038395494222640991,
    },
    intensity: 0.5,
    color: {
      r: 1.0,
      g: 1.0,
      b: 1.0,
      a: 1.0,
    },
    type: 'Directional',
    shadows: 'None',
  },
  {
    name: 'Fill',
    position: {
      x: -17.791378021240234,
      y: 22.640966415405273,
      z: -10.228687286376953,
    },
    rotation: {
      x: 25.131168365478516,
      y: 63.771434783935547,
      z: 0.0047812336124479771,
    },
    targetPosition: {
      x: -0.81212252378463745,
      y: 0.42469191551208496,
      z: -0.40011700987815857,
    },
    intensity: 0.5,
    color: {
      r: 1.0,
      g: 1.0,
      b: 1.0,
      a: 1.0,
    },
    type: 'Directional',
    shadows: 'None',
  },
  {
    name: 'Back',
    position: {
      x: 3.9996767044067383,
      y: 22.059318542480469,
      z: 23.152936935424805,
    },
    rotation: {
      x: 24.959196090698242,
      y: 197.22621154785156,
      z: 0.0043192100711166859,
    },
    targetPosition: {
      x: 0.26848754286766052,
      y: 0.42197272181510925,
      z: 0.86594098806381226,
    },
    intensity: 0.5,
    color: {
      r: 1.0,
      g: 1.0,
      b: 1.0,
      a: 1.0,
    },
    type: 'Directional',
    shadows: 'None',
  },
  {
    name: 'Key',
    position: {
      x: 17.326427459716797,
      y: 28.901697158813477,
      z: -9.0072317123413086,
    },
    rotation: {
      x: 45.929569244384766,
      y: 295.997314453125,
      z: 0.011346953921020031,
    },
    targetPosition: {
      x: 0.62516337633132935,
      y: 0.71848535537719727,
      z: -0.3048761785030365,
    },
    intensity: 2.0,
    color: {
      r: 1.0,
      g: 1.0,
      b: 1.0,
      a: 1.0,
    },
    type: 'Directional',
    shadows: 'None',
  },
];
